import { createSlice } from '@reduxjs/toolkit';

export const securitySlice = createSlice({
  name: 'security',
  initialState: {
    token: '',
    refresh_token: '',
    is_authenticated: false,
    user_name: '',
    email: '',
    groups: [],
    status: false,
    message: '',
  },
  reducers: {
    loadAuth: (state, action) => {
      /*
       * Deprecated in preference for useAuth hook and the authService
       */
      // Loads the auth details from local storage if it exists
      // try {
      //   const existing_state = cookies.get('CognitoIdToken');
      //   if (existing_state) {
      //     return { ...state, token: existing_state, is_authenticated: true };
      //   }
      //   // No existing state
      //   return { ...state };
      // } catch (Exception) {
      //   // If there's an error, just return the state as is
      //   return { ...state };
      // }
    },
    setAuth: (state, action) => {
      /*
       * Deprecated in preference for useAuth hook and the authService
       */
      // Sets the authentication details and saves to local storage
      // const new_state = {
      //   ...state,
      //   token: action.payload.token,
      //   refresh_token: action.payload.refresh_token,
      //   is_authenticated: true,
      //   user_name: action.payload.user_name,
      //   groups: action.payload.groups,
      //   email: action.payload.email,
      // };
      // return new_state;
    },
    logout: (state, action) => {
      /*
       * Deprecated in preference for useAuth hook and the authService
       */
      // cookies.remove('CognitoIdToken');
      // const new_state = {
      //   ...state,
      //   is_authenticated: false,
      //   message: 'logout',
      // };
      // return new_state;
    },
    sendCode: (state, action) => {
      /*
       * Deprecated in preference for useAuth hook and the authService
       */
      // const new_state = {
      //   ...state,
      //   status: action.payload.status,
      // };
      // return new_state;
    },
    setNewPassword: (state, action) => {
      /*
       * Deprecated in preference for useAuth hook and the authService
       */
      // const new_state = {
      //   ...state,
      //   status: action.payload.status,
      // };
      // return new_state;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setAuth } = securitySlice.actions;
export const { logout } = securitySlice.actions;
export const { loadAuth } = securitySlice.actions;
export const { sendCode } = securitySlice.actions;
export const { setNewPassword } = securitySlice.actions;
export default securitySlice.reducer;
