import React from 'react';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import TextField from '@mui/material/TextField';

const PasswordInput: React.FC<{
  password: string;
  onChange: (newValue: string) => void;
  hasPasswordError: boolean;
  isLoading: boolean;
  errors?: string;
  placeholder?: string;
  label?: string;
  mt?: number;
  onSubmitKeyHandler?: () => void;
  isNewPassword?: boolean;
  name?: string;
  isFocused?: boolean;
}> = ({
  password,
  onChange,
  onSubmitKeyHandler,
  hasPasswordError,
  isLoading,
  errors,
  placeholder,
  label = 'Password',
  mt = 0,
  isNewPassword = false,
  name,
  isFocused = false,
}) => {
  const [showPassword, setShowPassword] = React.useState(false);
  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  const onKeyHandler = (e: { code: string }) => {
    if (e.code === 'Enter' && onSubmitKeyHandler) {
      onSubmitKeyHandler();
    }
  };

  const onFocusChange = () => {
    if (showPassword) setShowPassword(false);
  };

  const autoCompleteUsed = isNewPassword ? 'new-password' : 'current-password';

  return (
    <TextField
      data-sl="mask"
      sx={{ marginTop: mt }}
      name={name}
      className={errors ? 'error' : ''}
      placeholder={placeholder}
      disabled={isLoading}
      autoComplete={showPassword ? 'off' : autoCompleteUsed}
      label={label}
      value={password}
      onChange={(e) => onChange(e.target.value)}
      onKeyDown={onKeyHandler}
      onBlur={onFocusChange}
      error={hasPasswordError}
      type={showPassword ? 'text' : 'password'}
      autoFocus={isFocused}
      InputProps={{
        autoComplete: autoCompleteUsed,
        endAdornment: (
          <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              onClick={handleClickShowPassword}
              onMouseDown={handleMouseDownPassword}
              edge="end"
            >
              {showPassword ? <VisibilityOff /> : <Visibility />}
            </IconButton>
          </InputAdornment>
        ),
      }}
      InputLabelProps={{
        shrink: true,
      }}
    />
  );
};

export default PasswordInput;
