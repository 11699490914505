import CalendarViewMonthIcon from '@mui/icons-material/CalendarViewMonth';
import GroupsIcon from '@mui/icons-material/Groups';
import InfoIcon from '@mui/icons-material/Info';
import { Layout, ProtectedRouteV2, useIdobaAuthV2 } from '@optika-solutions/shared-react';
import { Route, Routes } from 'react-router-dom';
import Account from 'views/Account';
import Login from 'views/login/Login';
import Activate from 'views/activate/Activate';
import Reset from 'views/reset/Reset';
import Module from 'views/Module';
import NotFound from 'views/NotFound';
import Version from 'views/Version';
import Maintenance from 'views/Maintenance';
import { MAINTENANCE_CONFIG } from 'utils/maintenance';

const Routing = () => {
  const { authService } = useIdobaAuthV2();

  const routeMenu = [{ link: '/modules', name: 'Module', icon: <CalendarViewMonthIcon /> }];

  // Ideally features shouldn't be merged to develop branch or made visible until they're
  // complete and able to put in front of customers (subject to review and feedback).
  // Hiding this one for now since it's already in the develop branch:
  if (process.env.NODE_ENV === 'development') {
    routeMenu.push({ link: '/accounts', name: 'Accounts', icon: <GroupsIcon /> });
  }

  // Only link to the version page in development
  if (process.env.NODE_ENV === 'development') {
    routeMenu.push({ link: '/version', name: 'Version', icon: <InfoIcon /> });
  }

  const layoutProps = {
    title: 'DiiMOS',
    menuItems: routeMenu,
    onLogout: authService.logout,
  };

  if (MAINTENANCE_CONFIG.maintenanceStatus === 'ONGOING') {
    return (
      <Routes>
        <Route path="*" element={<Maintenance />}></Route>
      </Routes>
    );
  } else {
    return (
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route path="/activate" element={<Activate />} />
        <Route path="/reset" element={<Reset />} />
        <Route path="*" element={<Login />} />
        <Route path="/not_found" element={<NotFound />}></Route>
        <Route path="/maintenance" element={<Maintenance />}></Route>

        <Route path="/version" element={<ProtectedRouteV2 />}>
          <Route path="/version" element={<Layout {...layoutProps} children={<Version />} />} />
        </Route>

        <Route path="/modules" element={<ProtectedRouteV2 />}>
          <Route path="/modules" element={<Layout {...layoutProps} children={<Module />} />} />
        </Route>

        <Route path="/accounts" element={<ProtectedRouteV2 />}>
          <Route path="/accounts" element={<Layout {...layoutProps} children={<Account />} />} />
        </Route>
      </Routes>
    );
  }
};

export default Routing;
