import React from 'react';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/system';

const ErrorLabel: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const theme = useTheme();
  return (
    <Typography
      sx={{
        width: 'inherit',
        fontWeight: 400,
        fontSize: 12,
        lineHeight: '167%',
        letterSpacing: 0.4,
        color: theme.palette.error.main,
        alignSelf: 'stretch',
        margin: '0px 12px 0px',
      }}
    >
      {children}
    </Typography>
  );
};

export default ErrorLabel;
