import { Container, Typography } from '@mui/material';
const Account = () => {
  return (
    <Container>
      <Typography variant="h4" sx={{ ml: 2 }}>
        Account page is Under Construction
      </Typography>
    </Container>
  );
};

export default Account;
