import axios, { AxiosInstance } from 'axios';

import authService from './authService';

export function getAuthApiUrl(): string {
  // Use an override if it was supplied
  if (process.env.REACT_APP_AUTH_API) return process.env.REACT_APP_AUTH_API;

  // Use our runtime config
  if (window.CONFIG) return window.CONFIG.auth.api;

  // Default to shared-dev
  return 'https://auth.shared.dev.phobos-cloud.com';
}

function getAkumenApiUrl(): string {
  // Use an override if it was supplied
  if (process.env.REACT_APP_AKUMEN_API) return process.env.REACT_APP_AKUMEN_API;

  // Use our runtime config
  if (window.CONFIG) return window.CONFIG.akumen.api;

  // Default to the MPN development env
  return 'https://akumen.mpn-dev.dev.phobos-cloud.com';
}

export class HttpClient {
  public client: AxiosInstance;

  constructor(baseUrl: string, useAccessToken = true) {
    this.client = axios.create({
      baseURL: baseUrl,
      timeout: 20000,
      withCredentials: useAccessToken,
    });

    if (useAccessToken) {
      this.client.interceptors.request.use(
        async (config) => {
          const token = authService.idToken;
          if (config && config.headers && token) {
            config.headers.Authorization = `Bearer ${token}`;
          }

          return config;
        },
        (err) => {
          console.error('httpClient', `Failed to create interceptor ${err}`);
        }
      );
    }
  }
}

export const authApi = new HttpClient(getAuthApiUrl()).client;
